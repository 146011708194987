export default {
  data: () => ({
    // productshareChartSeries: [100, 200, 300, 50],

    purchasingRequest: null,
    printingRequest: null,
    productionRequest: null,
    photographyRequest: null,

    productshareChartOptions: {
      chart: {
        type: "donut",
      },
      legend: {
        position: "bottom",
        fontSize: "12px",
        fontWeight: 500,
        horizontalAlign: "left",
        offsetX: -20,
        width: undefined,
        markers: {
          radius: 4,
          customHTML: undefined,
          onClick: undefined,
          offsetX: -2,
          offsetY: 0,
        },
      },
      labels: ["Created", "Approved", "Canceled", "Has Job Order"],
      colors: ["#C19FDF", "#9FDFCD", "#FFAAB9", "#7FA5EB"],
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: 'dark',
          gradientToColors:["#B696D1", "#88C6B4", "#FF8097", "#7FA5EB"],
          shadeIntensity: 1,
          type: 'vertical',
          opacityFrom: 1,
          opacityTo: 0.8,
        },
      },
      stroke: {
        show: false,
      },
      plotOptions: {
        pie: {
          donut: {
            size: "55%",
            labels: {
              show: false,
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
      },

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 300,
            },
            legend: {
              show: false,
              position: "bottom",
              // offsetY: 0,
            },
          },
        },
      ],
    },
  }),
  methods: {
    itemsInObjectToArray(count, ...filteredElements) {
      let myCount = count;
      let myArray = [];
      filteredElements.forEach((el) => {
        myArray.push(el);
      });
      return { myCount, myArray };
    },
    getDataTypes(data) {
      this.purchasingRequest = this.itemsInObjectToArray(
        data.Purchasing.count,
        data.Purchasing.created,
        data.Purchasing.approved,
        data.Purchasing.canceled,
        data.Purchasing.have_jo
      );
      this.printingRequest = this.itemsInObjectToArray(
        data.Printing.count,
        data.Printing.created,
        data.Printing.approved,
        data.Printing.canceled,
        data.Printing.have_jo
      );
      this.productionRequest = this.itemsInObjectToArray(
        data.Production.count,
        data.Production.created,
        data.Production.approved,
        data.Production.canceled,
        data.Production.have_jo
      );
      this.photographyRequest = this.itemsInObjectToArray(
        data.Photography.count,
        data.Photography.created,
        data.Photography.approved,
        data.Photography.canceled,
        data.Photography.have_jo
      );
    },
  },
};

<template>
  <section class="conOfLandingPage">
    <v-container fluid>
      <v-row
        v-if="listData && Object.keys(listData).length > 0"
        class="justify-center"
      >
        <v-col lg="9" md="8" cols="12">
          <div class="conOfFirstSection">
            <v-row>
              <v-col md="3" cols="12">
                <div class="addPNewProject pb-4">
                  <div class="headerSec">Add Project</div>
                  <div class="descriptionSec">
                    Create a new project, To Enable to Create Requests
                  </div>
                </div>
                <div class="conBtnCreation">
                  <v-btn
                    class="addTxtPrimaryWithIcon"
                    text
                    :ripple="false"
                    :to="{
                      name: 'allProductsReport',
                      query: { dialogProjectParam: '1' },
                    }"
                  >
                    <img src="@/assets/img/addIcon.svg" alt="" />
                    CREATE NEW PROJECT
                  </v-btn>

                </div>
              </v-col>
              <v-col md="9" cols="12">
                <v-row
                  v-if="
                    listData.project_head && listData.project_head.length > 0
                  "
                >
                  <v-col
                    md="4"
                    v-for="item of listData.project_head"
                    :key="item.id"
                  >
                    <router-link
                      class="linkConOfCardClient"
                      :to="{
                       name: user.children_id==8 ?'productionAllRequests':user.children_id==6 ?'photographyAllRequests':user.children_id==5 ?'printingAllRequests':'PurchasingAllRequests',

                        params: { id: item.id },
                      }"
                      @click.native="setProjectItems(item.id)"
                    >
                      <v-card
                        class="conOfCardClient"
                        color="#fede91"
                        dark
                        max-width="400"
                      >
                        <v-card-title>
                          <div class="conOfCardTitle">
                            <div class="headerClientName">Project Name</div>
                            <div class="projectNameCom">
                              {{ item.project_name }}
                            </div>
                          </div>
                        </v-card-title>
                             <v-card-title class="conOfCardTitlecom">
                          <div class="conOfCardTitle">
                            <div class="headerClientName">Company</div>
                            <div class="clientNameCom">
                              {{ item.client_company }}
                            </div>
                          </div>
                        </v-card-title>
                        <v-card-text class="text-h5 font-weight-bold">
                          <div class="conOfContent">
                          <!--   <div class="conAssigned mb-3">
                              <div class="assignedTo">Assigned Members</div>
                              <div class="assignedImages d-flex">
                                <img
                                  src="https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50"
                                  alt=""
                                />
                                <img
                                  src="https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50"
                                  alt=""
                                />
                                <img
                                  src="https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50"
                                  alt=""
                                />
                                <img
                                  src="https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50"
                                  alt=""
                                />
                                <div class="plusNumber">+3</div>
                              </div>
                            </div> -->
                            <div class="conProgress mb-5">
                              <div
                                class="
                                  headerProgress
                                  d-flex
                                  justify-space-between
                                "
                              >
                                <div class="reqCom">Requests Completion</div>
                                <div
                                  class="precentage"
                                  :class="[
                                    parseInt(item.request_completion) <= 30
                                      ? 'redProg'
                                      : '',
                                    parseInt(item.request_completion) <= 60
                                      ? 'orangeProg'
                                      : '',
                                    parseInt(item.request_completion) <= 100
                                      ? 'greenProg'
                                      : '',
                                  ]"
                                >
                                  {{ item.request_completion }}%
                                </div>
                              </div>
                              <div class="contentProgress">
                                <!-- greenProg -->
                                <v-progress-linear
                                  :class="[
                                    parseInt(item.request_completion) <= 30
                                      ? 'redProg'
                                      : '',
                                    parseInt(item.request_completion) <= 60
                                      ? 'orangeProg'
                                      : '',
                                    parseInt(item.request_completion) <= 100
                                      ? 'greenProg'
                                      : '',
                                  ]"
                                  v-model="item.request_completion"
                                  height="5"
                                  rounded
                                ></v-progress-linear>
                              </div>
                            </div>
                          </div>
                        </v-card-text>
                      </v-card>
                    </router-link>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row
              class="mt-5"
              v-if="listProjectRecent && listProjectRecent.length > 0"
            >
              <v-col md="12">
                <div class="conOfTableRecentQuote">
                  <v-data-table
                    :headers="headers"
                    :items="listProjectRecent"
                    :items-per-page="5"
                    hide-default-footer
                  >
                    <template v-slot:[`item.created_at`]="{ item }">
                      <div>{{ item.created_at | formatDate }}</div>
                    </template>
                    <template v-slot:[`item.status`]="{ item }">
                      <div
                        class="statusType"
                        :class="[
                          item.status_id == '0' ? 'canceled' : '',
                          item.status_id == '1' ? 'created' : '',
                          item.status_id == '2' ? 'done' : '',
                          item.status_id == '6' ? 'approved' : '',
                          item.status_id == '7' ? 'hasJO' : '',
                        ]"
                      >
                        {{ item.status_message }}
                      </div>
                    </template>
                  </v-data-table>
                </div>
              </v-col>
            </v-row>
            <template>
              <v-row>
                <v-col cols="12" class="pb-0">
                  <div class="headerOfCharts">REQUESTS OVERVIEW</div>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  md="3"
                  cols="12"
                  v-if="
                    purchasingRequest.myArray &&
                    purchasingRequest.myArray.length > 0
                  "
                >
                  <v-card class="eachChartCard" max-width="100%">
                    <v-card-title class="titleOfCard">
                      <div class="titleCon">
                        <div class="nameChart">PURCHASING REQUEST</div>
                        <div class="total">{{ purchasingRequest.myCount }}</div>
                      </div>
                    </v-card-title>
                    <v-card-text class="conOfCardTxtChart">
                      <div class="conChart">
                        <VueApexCharts
                          type="donut"
                          :options="productshareChartOptions"
                          :series="purchasingRequest.myArray"
                          height="250"
                        />
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col
                  md="3"
                  cols="12"
                  v-if="
                    printingRequest.myArray &&
                    printingRequest.myArray.length > 0
                  "
                >
                  <v-card class="eachChartCard" max-width="100%">
                    <v-card-title class="titleOfCard">
                      <div class="titleCon">
                        <div class="nameChart">PRINTING REQUESTS</div>
                        <div class="total">{{ printingRequest.myCount }}</div>
                      </div>
                    </v-card-title>
                    <v-card-text class="conOfCardTxtChart">
                      <div class="conChart">
                        <VueApexCharts
                          type="donut"
                          :options="productshareChartOptions"
                          :series="printingRequest.myArray"
                          height="250"
                        />
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col
                  md="3"
                  cols="12"
                  v-if="
                    productionRequest.myArray &&
                    productionRequest.myArray.length > 0
                  "
                >
                  <v-card class="eachChartCard" max-width="100%">
                    <v-card-title class="titleOfCard">
                      <div class="titleCon">
                        <div class="nameChart">PRODUCTION REQUESTS</div>
                        <div class="total">{{ productionRequest.myCount }}</div>
                      </div>
                    </v-card-title>
                    <v-card-text class="conOfCardTxtChart">
                      <div class="conChart">
                        <VueApexCharts
                          type="donut"
                          :options="productshareChartOptions"
                          :series="productionRequest.myArray"
                          height="250"
                        />
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col
                  md="3"
                  cols="12"
                  v-if="
                    photographyRequest.myArray &&
                    photographyRequest.myArray.length > 0
                  "
                >
                  <v-card class="eachChartCard" max-width="100%">
                    <v-card-title class="titleOfCard">
                      <div class="titleCon">
                        <div class="nameChart">PHOTOGRAPHY REQUESTS</div>
                        <div class="total">
                          {{ photographyRequest.myCount }}
                        </div>
                      </div>
                    </v-card-title>
                    <v-card-text class="conOfCardTxtChart">
                      <div class="conChart">
                        <VueApexCharts
                          type="donut"
                          :options="productshareChartOptions"
                          :series="photographyRequest.myArray"
                          height="250"
                        />
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </template>
          </div>
        </v-col>
        <v-col lg="3" md="4" cols="12">
          <div class="conOfSecondSection">
            <div class="conOfCardSettings" v-if="user.role_id == 1">
              <img class="patternDep" src="@/assets/img/pattern.png" alt="" />
              <v-row>
                <v-col cols="12">
                  <v-card
                    class="cartCon_vCard"
                    dark
                    width="100%"
                    height="110"
                    v-if="listData.departments_count"
                  >
                    <router-link :to="{ name: 'settingDepartments' }">
                      <v-card-text class="conOfCardTxt">
                        <div
                          class="
                            eachCard
                            d-flex
                            align-center
                            justify-space-between
                          "
                        >
                          <div class="textSec">
                            <div class="nameTxt">All Departments</div>
                            <div class="desName">
                              {{ listData.departments_count }}
                            </div>
                          </div>
                          <div class="iconImgCon">
                            <img
                              src="@/assets/img/settings/departementWhite.png"
                              alt=""
                            />
                          </div>
                        </div>
                      </v-card-text>
                    </router-link>
                  </v-card>
                  <v-card
                    class="cartCon_vCard"
                    dark
                    width="100%"
                    height="110"
                    v-if="listData.project_head"
                  >
                    <router-link :to="{ name: 'settingCompanies' }">
                      <v-card-text class="conOfCardTxt">
                        <div
                          class="
                            eachCard
                            d-flex
                            align-center
                            justify-space-between
                          "
                        >
                          <div class="textSec">
                            <div class="nameTxt">All Companies</div>
                            <div class="desName">
                              {{listData.companies_count}}
                            </div>
                          </div>
                          <div class="iconImgCon">
                            <img
                              src="@/assets/img/settings/factory-white.png"
                              alt=""
                            />
                          </div>
                        </div>
                      </v-card-text>
                    </router-link>
                  </v-card>
                  <v-card
                    class="cartCon_vCard"
                    dark
                    width="100%"
                    height="110"
                    v-if="listData.roles_count"
                  >

                    <router-link :to="{ name: 'settingRoles' }">
                      <v-card-text class="conOfCardTxt">
                        <div
                          class="
                            eachCard
                            d-flex
                            align-center
                            justify-space-between
                          "
                        >
                          <div class="textSec">
                            <div class="nameTxt">All Roles</div>
                            <div class="desName">
                              {{ listData.roles_count }}
                            </div>
                          </div>
                          <div class="iconImgCon">
                            <img
                              src="@/assets/img/settings/roleWhite.png"
                              alt=""
                            />
                          </div>
                        </div>
                      </v-card-text>
                    </router-link>
                  </v-card>
                  <v-card
                    class="cartCon_vCard"
                    dark
                    width="100%"
                    height="110"
                    v-if="listData.users_count"
                  >
                    <router-link :to="{ name: 'settingUsers' }">
                      <v-card-text class="conOfCardTxt">
                        <div
                          class="
                            eachCard
                            d-flex
                            align-center
                            justify-space-between
                          "
                        >
                          <div class="textSec">
                            <div class="nameTxt">All User</div>
                            <div class="desName">
                              {{ listData.users_count }}
                            </div>
                          </div>
                          <div class="iconImgCon">
                            <img
                              src="@/assets/img/settings/usersWhite.png"
                              alt=""
                            />
                          </div>
                        </div>
                      </v-card-text>
                    </router-link>
                  </v-card>
                </v-col>
              </v-row>
            </div>
            <div class="recentJobOrders">
              <v-row>
                <v-col cols="12">
                  <v-card class="cardRecentOrders" elevation="0" width="100%">
                    <v-card-title class="titleOfCard"
                      >Recent Job Orders</v-card-title
                    >
                    <v-card-text
                      class="conOfCardTxt"
                      v-if="
                        listData.job_orders && listData.job_orders.length > 0
                      "
                    >
                      <div
                        class="eachCard"
                        v-for="(item, index) in listData.job_orders"
                        :key="index"
                      >
                        <router-link
                          class="linkConOfCardJobOrder"
                          :to="{
                            name: 'jobOrdersProcess',
                            params: { id: item.id },
                          }"
                        >
                          <div class="statusOrderCon">
                            <div
                              class="statusOrder"
                              :class="[
                                item.status == 7 ? 'greenStatus' : '',
                                item.status == 4 ? 'orangeStatus' : '',
                                item.status == 6 || item.status == 8
                                  ? 'redStatus'
                                  : '',
                                item.status != 7 &&
                                item.status != 4 &&
                                item.status != 6 &&
                                item.status != 8
                                  ? 'otherStatus'
                                  : '',
                              ]"
                            ></div>
                          </div>
                          <div class="textSec">
                            <div class="nameTxt">{{ item.po }}</div>
                            <div class="desName">
                              Client: {{ item.client_company }}
                            </div>
                          </div>
                        </router-link>
                      </div>
                    </v-card-text>
                    <div v-else class="noData ">No data available</div>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-col>
      </v-row>
      <div class="loaderContainer" v-else-if="isLoading">
        <v-progress-circular
          :size="120"
          :width="4"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <v-row v-else>
        <v-col cols="12" class="mt-7">
          <EmptyState />
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>
<script>
import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();
import EmptyState from "@/modules/shared/components/emptystate";
import VueApexCharts from "vue-apexcharts";
import requestOverview from "../../mixins/requestOverview";
import { mapGetters } from "vuex";
import store from "@/store";

export default {
  mixins: [requestOverview],
  data: () => ({
    headers: [
      {
        text: "RECENT REQUESTS",
        align: "start",
        value: "recent_request",
        sortable: false,
      },
      { text: "PROJECT NAME", value: "project_name", sortable: false },
      { text: "CREATED", value: "created_at", sortable: false },
      { text: "SALES CONTACT", value: "user_name", sortable: false },
      { text: "TIMELINE", value: "project_timeline", sortable: false },
      { text: "STATUS", value: "status", sortable: false },
    ],
    listProjectRecent: [],
    listData: {},
    isLoading: false,
  }),
  computed: {
    ...mapGetters(["user"]),
  },
  components: {
    VueApexCharts,
    EmptyState,
  },

  methods: {
    setProjectItems(id) {
      apiServices.get(`projects/${id}`).then((res) => {
        if (res) {
          store.commit("setCreatedByUser", res.data.user);
          store.commit("setAssignTo", res.data.assign_to);
          store.commit("setProjectName", res.data.project_name);
          store.commit("setProjectCountry", res.data.country_id);
          store.commit("setpo_number", res.data.po_number);
        }
      });
    },

    getListData() {
      this.listData = {};
      this.isLoading = true;
      apiServices.get("home").then((res) => {
        if (res) {
          this.listData = res.data;
          this.listProjectRecent = res.data.projects;
          this.getDataTypes(res.data.types);
          this.isLoading = false;
        } else this.isLoading = false;
      });
    },
  },
  created() {
    this.getListData();
  },
};
</script>

<style lang="scss" scoped>
@import "./_landing-page.scss";
</style>
